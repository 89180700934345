.error-message-product {
  color: red;
  font-size: 0.9em;
  margin-top: -1px;
  margin-bottom: -5px;
  margin-left: 55px;
}

.error-message-quantity {
  color: red;
  font-size: 0.9em;
  margin-top: -18px;
  margin-left: 25px;
}

.error-message-mushroom-quantity {
  color: red;
  font-size: 0.9em;
  margin-top: -10px;
  margin-bottom: -1px;
  margin-left: 25px;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.input-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.input-row-customer-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  font-size: 0.9em;
  margin-top: -15px;
}

.row-header {
  margin-left: 8px;
}

.product-inventory-close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-size: 1.5em;
  height: 40px;
  line-height: 20px;
  text-align: center;
  width: 40px;
  margin-left: 80%;
}

.product-inventory-close-btn:hover {
  color: white;
  background: black;
}

.close-button {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.quantity-dropdown {
  width: 150px;
}

.phone-row-header-remove-product {
  width: 320px;
  padding-left: -55px;
}

.transaction-type-row-header {
  width: 320px;
  padding-left: 7px;
}

.report-table-container table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.report-table-container th,
.report-table-container td {
  border: 1px solid #d4d4d4;
  padding: 8px 12px;
  text-align: left;
}

.report-table-container th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.report-table-container tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

/* .report-table-container tbody tr:hover {
  background-color: #e6e6e6;
} */

.report-table-container h3 {
  border-bottom: 2px solid #d4d4d4;
  padding-bottom: 10px;
  margin-top: 30px;
}

.report-generation-output {
  flex: 1;
}

.days-indentation {
  margin-top: -15px;
  margin-left: 5px;
}

.yield-value-indentation {
  margin-top: -12px;
  margin-left: 5px;
}

.average-sub-bullet {
  margin-left: 15px;
  font-size: 95%;
}

.analytics-display {
  margin-left: 2%;
}

.header-for-analytics {
  margin-top: 1%;
  margin-bottom: -0.5%;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 10px;
}

.num-bags-error-message {
  position: absolute;
  color: red;
  font-size: 0.9em;
  right: 2%;
  top: 118px;
}

.growbags-list__add-growbags-btn {
  margin-bottom: 1rem;
}

.growbags-list__add-growbags-btn-container {
  background-color: #007bff;
  color: white;
  font-size: 0.8em;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  display: inline-block;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.growbags-list__add-growbags-btn-container:hover {
  background-color: #0069d9;
}

.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  text-decoration: none;
}

.btn--primary {
  background-color: #007bff;
  color: #fff;
}

.btn--primary:hover {
  background-color: #0056b3;
}

.btn--primary:active {
  background-color: #004499;
}

.mr-1 {
  margin-right: 0.25rem;
}

.growbags-dropdown-list {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.substrate-weight-container {
  margin-top: -10px;
  margin-left: 50px;
  width: 75%;
}

.growbags-close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-size: 1.5em;
  height: 40px;
  line-height: 20px;
  text-align: center;
  width: 40px;
  margin-left: 90%;
}

.growbags-modal-close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-size: 1.5em;
  text-align: center;
  width: 50px;
}

.growbags-close-btn:hover {
  color: white;
  background: black;
}

.growbags-modal-close-btn:hover {
  color: white;
  background: black;
}

.growbags-edit__textarea {
  width: 100%;
  min-height: 50px;
  resize: vertical;
}

.growbags-dropdown-list label,
.growbags-dropdown-list select {
  display: inline-block;
}

.growbags-list__add-growbag-btn-container {
  background-color: #007bff;
  color: white;
  font-size: 0.8em;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  display: inline-block;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.growbags-list__add-growbag-btn-container:hover {
  background-color: #0069d9;
}

.growbag {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  background-color: #f7fafc;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.growbag:hover {
  background-color: #b4c0d3;
}

.growbag__title {
  font-weight: bold;
  color: #4a5568;
  margin: 0;
}

.growbag_type {
  color: #718096;
  font-style: italic;
  margin: 0;
}

.growbags-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 1rem;
}

.property-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.property-box {
  display: inline-block;
  color: rgb(128, 128, 128);
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.property-box:hover {
  background-color: #3f3333;
  color: white;
}

.property-box.selected {
  font-weight: bold;
  color: #007bff;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
}

.properties-header {
  justify-content: center;
  margin-left: 10px;
}

.properties-selection-section {
  margin-top: -30px;
}

.selection-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px; /* Adjust the padding as needed */
  border-right: 1px solid #ccc; /* You can adjust the border size and color as needed */
  padding-right: 10px; /* Add spacing between sections */
}

.selection-header {
  text-align: center;
  text-decoration: underline;
}

.selection-contents {
  text-align: center;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yield-grade-input-container {
  margin-left: 2rem; /* Indent the input container */
  display: flex;
  flex-direction: column;
}

.yield-grade-input-container input {
  max-width: 50%; /* Set the maximum width for the input elements */
}

/* Below is the CSS for the toggle switch */
.toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 300px; /* Adjust this value based on your layout */
  margin-bottom: 1.3rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  margin: 4px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(17px);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.harvest-modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.harvest-modal-content {
  position: relative;
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  pointer-events: auto;
}

.growbags-modal-textarea {
  width: 100%;
  height: 60px;
}

.harvest-modal-grade-input {
  margin-left: 10%;
  width: 70%;
}

.toggle-weight-button {
  background-color: #3a5f8d; /* Darker shade of blue */
  color: white;
  border: none;
  padding: 8px 12px;
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 80px; /* Adjust the position from the top */
  right: 25px; /* Adjust the position from the right side */
  border-radius: 4px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20%; /* Adjust the width of the button */
  height: 10%; /* Adjust the height of the button */
}

.toggle-weight-button:hover {
  background-color: #2a4370; /* Slightly darker blue on hover */
}

.small-break {
  margin-top: 8px;
}

.harvest-batch-btn {
  position: absolute;
  width: 5%;
  top: -10px;
  right: 3px;
  font-size: 0.8rem; /* Adjust the font size to make the button smaller */
  padding: 5px 10px; /* Adjust the padding for a smaller button */
}

.discard-bags-btn {
  background-color: rgba(189, 13, 13, 0.843);
  position: absolute;
  width: 5%;
  top: 50px;
  right: 3px;
  font-size: 0.7rem;
  padding: 4px;
}

.discard-bags-btn:hover {
  background-color: rgba(255, 0, 0, 0.329);
}

.dry-batch-btn {
  position: absolute;
  width: 6%;
  top: 0;
  right: 3px;
  font-size: 0.8rem; /* Adjust the font size to make the button smaller */
  padding: 6px 12px; /* Adjust the padding for a smaller button */
}

.send-to-inventory-btn {
  position: absolute;
  width: 6%;
  top: 40px;
  right: 3px;
  font-size: 0.8rem; /* Adjust the font size to make the button smaller */
  padding: 6px 12px; /* Adjust the padding for a smaller button */
  background-color: rgba(200, 3, 43, 0.849);
}
.send-to-inventory-btn:hover {
  background-color: rgba(200, 3, 43, 0.45);
}

.batchIdNumber {
  font-weight: bold;
  color: black;
  font-size: 1.1rem;
  margin-bottom: -5px;
}

.harvest-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  pointer-events: none;
  touch-action: none;
}

.clickable-addition-button {
  position: absolute;
  bottom: 18.5%;
  right: 6%;
  cursor: pointer;
  color: #004499;
  font-size: 0.7rem;
  text-decoration: underline;
}

.clickable-addition-button:hover {
  color: blue;
}

.new-harvest-modal {
  z-index: 1002; /* This value should be higher than the z-index value of the harvest-modal */
}

.sort-label,
.sort-direction,
#groupByFilter,
#sortByFilter {
  display: inline-block;
  vertical-align: middle;
}

.sort-label {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: -13px;
}

.sort-direction {
  position: relative;
  left: 70px;
  bottom: 4px;
  padding-bottom: -10px;
  font-size: 0.8rem;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.sort-direction.ascending:hover,
.sort-direction.descending:hover {
  color: darkblue;
}

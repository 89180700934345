.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 10px;
}

.mushroom-details-list-add-btn {
  background-color: #007bff;
  color: white;
  font-size: 0.8em;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  display: inline-block;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.mushroom-details-list-add-btn:hover {
  background-color: #0069d9;
}

.x-remove-btn {
  width: 2%;
  color: white;
  cursor: pointer;
  font-size: 0.8em;
  padding: 5px 10px;
  margin: 5px;
  background-color: #dc3545;
}

.x-remove-btn:hover {
  background-color: #c82333;
}

.mushroom-details-list__add-item {
  width: 30%;
}

.close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-size: 1.5em;
  height: 40px;
  line-height: 20px;
  text-align: center;
  width: 40px;
  margin-left: 90%;
}

.close-btn:hover {
  color: white;
  background: black;
}

.add-item-btn {
  background-color: #046711;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.8em;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  display: block;
  margin: 10px auto 0;
  transition: background-color 0.3s;
}

.add-item-btn:hover {
  color: white;
  background-color: rgba(2, 10, 0, 0.666);
}

.inventory-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.inventory-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  max-height: 90%;
  overflow-y: auto;
}

.inventory-modal-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.inventory-modal-subheader {
  margin-bottom: 1.5rem;
}

.inventory-modal-data {
  margin-bottom: 1.5rem;
}

.inventory-modal-warning {
  color: red;
  margin-bottom: 1.5rem;
}

.submit-button,
.close-button {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.submit-button {
  background-color: #4caf50;
  color: white;
  margin-right: 1rem;
  width: 20%;
}

.close-button {
  background-color: #f44336;
  color: white;
  width: 20%;
}

.logo-container {
  position: relative;
  margin-bottom: -20px;
}

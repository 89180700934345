.fully-harvested {
  font-family: Arial, sans-serif;
}

.batch-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

.batch-info {
  font-weight: bold;
  margin-bottom: 5px;
}

.harvest-info {
  font-size: 0.9em;
  color: #666666;
}

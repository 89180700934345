.phone-row-header {
  display: flex;
  align-items: center;
}

.phone-row-header input {
  margin: 0 5px;
  text-align: center;
  font-size: 1rem;
}

.phone-row-header input:nth-child(1),
.phone-row-header input:nth-child(2) {
  width: 70px;
}

.phone-row-header input:nth-child(3) {
  width: 90px;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 10px;
}

.culture-list__add-culture-btn-container {
  background-color: #007bff;
  color: white;
  font-size: 0.8em;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  display: inline-block;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.culture-list__add-culture-btn-container:hover {
  background-color: #0069d9;
}

.culture-item {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  background-color: #f7fafc;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.culture-list {
  width: 30%;
}

.culture-item:hover {
  background-color: #e0e0e0;
}

.culture-name {
  margin: 0;
  padding: 0;
}

.mushroom-type {
  margin-top: 1rem;
  margin-bottom: 0.8rem;
  font-size: 1.4em;
  color: #4a5568;
  font-style: italic;
}

/* Dropdown menu styles */
select {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 3px 8px;
  font-size: 0.9em;
  color: #495057;
  margin-left: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

select:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.culture-edit {
  position: relative;
}

.culture-edit__label-indented {
  margin-left: 15px;
}

.culture-close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-size: 1.5em;
  height: 40px;
  line-height: 20px;
  text-align: center;
  width: 40px;
  margin-left: 90%;
}

.culture-close-btn:hover {
  color: white;
  background: black;
}

textarea#cultureNote {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px;
  font-size: 0.9em;
  resize: vertical;
}

button[type="submit"],
button[type="delete"] {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 0.8em;
  padding: 5px 10px;
  margin: 5px;
  width: 80px;
}

button[type="submit"] {
  background-color: #007bff;
}

button[type="submit"]:hover {
  background-color: #0069d9;
}

button[type="delete"] {
  background-color: #dc3545;
}

button[type="delete"]:hover {
  background-color: #c82333;
}

/* Below is the CSS for the toggle switch */
.toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 300px; /* Adjust this value based on your layout */
  margin-bottom: 1.3rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  margin: 4px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(17px);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.add-culture-ingredient-button {
  background-color: #007bff;
  color: white;
  font-size: 0.7em;
  padding: 4px 10px;
  border: none;
  cursor: pointer;
  width: auto;
  transition: background-color 0.3s;
  margin-bottom: 15px;
  margin-top: -10px;
}

.add-culture-ingredient-button:hover {
  background-color: #0069d9;
}

.culture-edit__nutrient {
  display: flex;
  align-items: center;
}

.culture-edit__ingredient-input-box {
  width: 200px;
  margin-left: 10px;
}

.culture-grams-label {
  white-space: nowrap;
  padding-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.culture-nutrient-label {
  white-space: nowrap;
  padding-bottom: 10px;
}

.culture-edit__nutrients {
  margin-left: 20px;
}

.culture-nutrient-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.remove-nutrient-btn {
  background: none;
  border: none;
  color: #e53e3e;
  cursor: pointer;
  font-size: 0.7em;
  margin-bottom: 2rem;
  align-self: center;
}

.remove-nutrient-btn:hover {
  color: #c53030;
  background: black;
}

.remove-nutrient-btn::before {
  content: "X";
}

.remove-nutrient-btn:hover::before {
  content: "Remove";
}

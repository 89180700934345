.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 10px;
}

.inventory-transaction-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 100;
}

.inventory-transaction-modal-content {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 50px;
  border-radius: 5px;
  width: 50%;
  max-height: 80%; /* Adjust this value as per your need */
  overflow-y: auto;
  max-width: 500px;
}

.inventory-transaction-modal-header {
  display: flex;
  margin-bottom: 0.2rem;
  justify-content: space-between;
}

.inventory-transaction-modal-close-btn {
  position: absolute;
  left: 90%;
  top: 1%;
  display: flex;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: black;
}

.inventory-transaction-modal-close-btn:hover {
  color: white;
  background: black;
}

.inventory-transaction-modal-content form {
  display: flex;
  flex-direction: column;
}

.inventory-transaction-modal-content form label {
  margin-bottom: 10px;
}

.inventory-transaction-modal-content form input,
.inventory-transaction-modal-content form select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.inventory-transaction-modal-content form button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #008cba; /* Change this to your preferred color */
  color: white;
  cursor: pointer;
  margin-top: 10px;
}

.inventory-transaction-modal-content form button:hover {
  background-color: #007b9a; /* Darker shade of the button color */
}

.transactions-add-a-note-textarea {
  width: 100%;
  min-height: 50px;
  resize: vertical;
}

.inventory-modal-clickable-addition-button {
  position: relative;
  left: 65%;
  top: 25px;
  cursor: pointer;
  color: #004499;
  font-size: 0.7rem;
  text-decoration: underline;
}

.inventory-modal-clickable-addition-button:hover {
  color: blue;
}

.add-inventory-modal-clickable-addition-button {
  position: relative;
  left: 65%;
  top: 7px;
  cursor: pointer;
  color: #004499;
  font-size: 0.7rem;
  text-decoration: underline;
}

.add-inventory-modal-clickable-addition-button:hover {
  color: blue;
}

.add-inventory-total-weight {
  margin-top: -33px;
}

/* May be able to remove this section */
.total-display {
  position: relative;
  bottom: -20px;
}

.inventory-display {
  padding: 10px 0;
  border-bottom: 1px solid #000;
}

.inventory-display dt {
  display: flex;
  font-weight: bold;
  margin-bottom: 5px;
  justify-content: center;
}

.inventory-display dd {
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-bottom: 10px;
}

.wrapped-total-display {
  margin-top: -20px;
}

.dollar-input-container {
  display: flex;
  align-items: center;
}

.dollar-input-container input {
  margin-left: 5px;
}

.whole-dollar-style-container {
  margin-left: -110px;
}

.dollar-symbol {
  margin-bottom: 15px;
}

.phone-input-styling {
  margin-left: -130px;
  margin-bottom: 20px;
}

.whole-dollar-style-container {
  margin-left: -40%;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 10px;
}

/* Aligning checkboxes with labels */
.growthReportCheckbox {
  vertical-align: middle;
  width: 20px; /* Increase the size */
  height: 20px; /* Increase the size */
  margin-top: 5px;
}

/* Adjusting the width of date input boxes */
input[type="date"] {
  width: 250px; /* Adjust this value as per your preference */
}

/* Indenting the options under Growth/Harvest */
.growthHarvestNotesSubOptions {
  margin-left: 20px; /* Indentation */
}

.gradeYieldOptions {
  margin-left: 20px; /* Adjust the value as per your preference */
}

.reportContainer {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

section {
  width: 25%;
}

.report-output {
  flex: 1; /* This will make the ReportOutput take up the remaining space */
  text-align: center; /* This will center the content inside ReportOutput */
}

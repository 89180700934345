.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 10px;
}

.harvest-checkbox-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.harvest-checkbox-list label {
  margin-right: 1rem;
}

.harvest-groupby-sortby {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.harvest-groupby-sortby label,
.harvest-groupby-sortby select {
  display: inline-block;
}

.harvest-list .growbag {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  background-color: #f7fafc;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.harvest-list .growbag:hover {
  background-color: #b4c0d3;
}

.harvest-list .growbag__title {
  font-weight: bold;
  color: #4a5568;
  margin: 0;
}

.harvest-list .growbag_type {
  color: #718096;
  font-style: italic;
  margin: 0;
}

.growbags-container-harvest-list {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding-top: 3.1rem;
  gap: 1rem;
}

.harvest-dropdown-list {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.select-width {
  width: 100%;
}

.harvest-dropdown-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 1rem;
}

.harvested-bags-edit {
  width: 60%;
}

.harvested-dropdown-menu {
  margin: 5px;
  margin-left: -0.5px;
  margin-bottom: 15px;
}

.sort-label,
.sort-direction,
#groupByFilter,
#sortByFilter {
  display: inline-block;
  vertical-align: middle;
}

.sort-label {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: -13px;
}

.sort-direction {
  position: relative;
  left: 70px;
  bottom: 4px;
  padding-bottom: -10px;
  font-size: 0.8rem;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.sort-direction.ascending:hover,
.sort-direction.descending:hover {
  color: darkblue;
}

.edit-batch-clickable-addition-button {
  position: relative;
  left: 47%;
  top: 25px;
  cursor: pointer;
  color: #004499;
  font-size: 0.7rem;
  text-decoration: underline;
}

.edit-batch-clickable-addition-button:hover {
  color: blue;
}

.retention-percentage-header {
  position: relative;
  left: 65%;
  bottom: 5px;
  margin: -3px;
  margin-top: -10px;
  margin-bottom: -15px;
}

.retention-percentage-number {
  position: relative;
  left: 69%;
  bottom: 65px;
  margin-bottom: -35px;
}

.total-dried-yield-input {
  width: 55%;
}

.total-retention-percentage-number {
  position: relative;
  left: 72%;
  bottom: 65px;
  margin-bottom: -35px;
}

.substrate-weight-input {
  margin-top: -25px;
  margin-left: 33px;
  width: 50%;
}

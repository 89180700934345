.dried-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 3%;
  z-index: 1000;
}

.dried-modal-content {
  position: relative;
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  max-height: 90vh;
  pointer-events: auto;
  transition: transform 0.1s linear;
  overflow-y: auto;
}

.dried-modal-close-btn {
  position: absolute;
  left: 90%;
  top: 1%;
  display: flex;
  justify-content: center;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-size: 1.5em;
  text-align: center;
  width: 50px;
}

.dried-modal-close-btn:hover {
  color: white;
  background: black;
}

.dried-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2rem;
}

.fresh-yield-display-tag {
  margin-top: -0.8rem;
  margin-bottom: 0.4rem;
  margin-left: 0rem;
}

.dried-modal-grade-container {
  margin-left: 4rem; /* Indent the input container */
  display: flex;
  flex-direction: column;
}

.dried-modal-grade-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.dried-modal-grade-input-container input {
  width: 70%;
  max-width: 70%; /* Set the maximum width for the input elements */
}

.dried-modal-grade-input-container span {
  flex-grow: 1;
  text-align: right;
  margin-right: 7%;
}

.percentage-column-title {
  position: absolute;
  right: 2%;
  margin-top: 1%;
  text-decoration: underline;
}

.dried-clickable-addition-button {
  position: absolute;
  bottom: 8.5%;
  right: 6%;
  cursor: pointer;
  color: #004499;
  font-size: 0.7rem;
  text-decoration: underline;
}

.dried-clickable-addition-button:hover {
  color: blue;
}

.drying-note-modal-textarea {
  width: 100%;
  height: 60px;
}

.drying-note-total-area {
  margin-top: 15px;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 10px;
}

.substrate-list__add-substrate-btn-container {
  background-color: #007bff;
  color: white;
  font-size: 0.8em;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  display: inline-block;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.substrate-close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-size: 1.5em;
  height: 40px;
  line-height: 20px;
  text-align: center;
  width: 40px;
  margin-left: 90%;
}

.substrate-close-btn:hover {
  color: white;
  background: black;
}

.substrate-list__add-substrate-btn-container:hover {
  background-color: #0069d9;
}

.substrate-edit__textarea {
  width: 100%;
  min-height: 50px;
  resize: vertical;
}

.substrate-list {
  width: 30%;
  overflow-y: auto;
}

.substrate-view {
  margin-left: 2rem;
}

.substrate-edit {
  width: 80%;
  margin-left: 2rem;
}

.mushroom-type-header {
  font-size: 1.2rem;
  color: #4a5568;
  margin-top: 1rem;
  margin-bottom: 0.8rem;
}

.substrate {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  background-color: #f7fafc;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.substrate:hover {
  background-color: #b4c0d3;
}

.substrate__title {
  font-weight: bold;
  color: #4a5568;
  margin: 0;
}

.substrate_type {
  color: #718096;
  font-style: italic;
  margin: 0;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.substrate button {
  background-color: #e53e3e;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;
}

.substrate button:hover {
  background-color: #c53030;
}

.substrate-edit__ingredient {
  display: flex;
  align-items: center;
  margin-bottom: -2.5rem;
}

.substrate-edit__ingredients {
  margin-bottom: 2rem;
}
.substrate-edit__add-ingredient-btn {
  background-color: #007bff;
  color: white;
  font-size: 0.7em;
  padding: 4px 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  transition: background-color 0.3s;
}

.substrate-edit__add-ingredient-btn:hover {
  background-color: #0069d9;
}

.substrate-edit-ingredient-btn {
  background-color: #007bff;
  color: white;
  font-size: 0.8em;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 50%; /* Adjust the width as needed */
  margin: auto; /* Center the button */
}

.substrate-edit-ingredient-btn:hover {
  background-color: #0069d9;
}

.substrate-edit__label.ingredient-label {
  display: flex;
  margin-right: 0.1rem;
  margin-bottom: 1rem;
}

.substrate-edit__label.grams-label {
  display: flex;
  margin-right: 0.1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.remove-ingredient-btn {
  background: none;
  border: none;
  color: #e53e3e;
  cursor: pointer;
  font-size: 0.7em;
  margin-bottom: 2rem;
  align-self: center;
}

.remove-ingredient-btn:hover {
  color: #c53030;
  background: black;
}

.remove-ingredient-btn::before {
  content: "X";
}

.remove-ingredient-btn:hover::before {
  content: "Remove";
}

.substrate-edit__ingredient-input-box {
  width: 250px;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 10px;
}

body {
  background-color: #f0f0f0;
  font-family: "Arial", sans-serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 350px;
}

.login-header {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
  margin-bottom: 1rem;
  padding: 12px 20px;
  width: 100%;
}

button {
  background-color: #1f2cde;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

button:hover {
  background-color: #1a85e9;
}

.error-message {
  color: red;
  margin-bottom: 1rem;
  text-align: center;
}

.logout-button {
  position: fixed;
  top: 5px;
  right: 15px;
  width: 100px;
  background-color: gray;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 0.8rem;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;
  z-index: 10;
}

.logout-container {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 10;
}

.logout-button:hover {
  background-color: #6c757d;
}

.login-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.display-button {
  margin-right: 15px;
  margin-left: 15px;
}

.signup-info {
  text-align: center;
  margin-top: 1rem;
}

.login-title {
  text-align: center;
  margin-bottom: 1rem;
}

.logged-in-wrapper {
  margin-top: 50px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

/* Add styles for the logo image */
.logo-container img {
  max-width: 150px;
  height: auto;
}

.splitting-x {
  font-size: 3rem;
  margin: 15px;
}

.verification-button {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 0.8em;
  padding: 5px 10px;
  margin: 5px;
  width: 15rem;
}

.verification-button:hover {
  background-color: #0069d9;
}

/* Styles Back to Homepage */

.back-to-homepage {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid #007bff;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  height: 40px;
  width: 40px;
  z-index: 10;
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  transition: width 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease;
}

.back-to-homepage:hover {
  background-color: #e9f0ff;
  border-color: #0056b3;
  width: 150px;
  border-radius: 15px;
}

/* Add the arrow shape as a pseudo-element */
.back-to-homepage:before {
  content: "";
  border: solid #007bff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  position: absolute;
  top: 50%;
  left: 16px;
  margin-top: -3px;
}

.back-to-homepage:hover:before {
  border-color: #0056b3;
}

/* Add the text inside the button */
.back-to-homepage span {
  display: none;
  position: absolute;
  left: 35px;
}

.back-to-homepage:hover span {
  display: block;
}

.sign-up-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.signup-form-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 350px;
}

/* Update the .back-to-homepage class styles */
.back-to-homepage {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid #007bff;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  height: 40px;
  width: 40px;
  z-index: 10;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  transition: width 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease;
}

.centered-header {
  text-align: center;
}

/* Add styles for .logged-in-container */
.logged-in-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.email-sent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.centered-text {
  text-align: center;
}

.return-to-homepage-btn {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  padding: 12px 20px;
  margin-top: 1rem;
  width: 45%;
  transition: background-color 0.2s ease;
}

.return-to-homepage-btn:hover {
  background-color: #0069d9;
}

.adjusted-margin-header {
  margin-bottom: -20px; /* Adjust this value to control the gap below the header */
}

/* Add styles for .adjusted-margin-button */
.adjusted-margin-button {
  margin-top: 45px; /* Adjust this value to control the gap above the button */
}

.clickable-signup {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.clickable-signup:hover {
  color: #0066cc; /* Adjust this value to change the text color on hover */
}

.forgot-password-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.forgot-password-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 400px;
  max-width: 90%;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 3.5rem;
  font-size: 24px;
  cursor: pointer;
  background: none;
  color: black;
}

.close-modal:hover {
  color: white;
  background: black;
}

.verification-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.verification-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
  text-align: center;
}

.logo-modal {
  width: 100px;
  margin-bottom: 20px;
}

.return-to-homepage-btn {
  background-color: #1d2dac;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 50%;
  text-align: center;
}

.return-to-homepage-btn:hover {
  opacity: 0.8;
}

.return-to-login-screen-btn {
  background-color: #1d2dac;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  padding: 12px 20px;
  margin-top: 1rem;
  width: 45%;
  transition: background-color 0.2s ease;
}

.return-to-login-screen-btn:hover {
  background-color: #0069d9;
}

.marketing-emails-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.switch {
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.switch-on {
  background-color: #4caf50;
}

.switch-handle {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left 0.2s;
}

.switch-on .switch-handle {
  left: 21px;
}

.marketing-message {
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 1rem;
}

.switch-label {
  position: absolute;
  font-size: 0.6rem;
  color: white;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
}

.switch-on .switch-label.yes {
  left: 0.2rem;
  color: white;
}

.switch-off .switch-label.no {
  right: 0.35rem;
  color: black;
}

.merchants-logo {
  max-width: 200px; /* Adjust this value to control the size */
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
}

.partnership-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.partnership-text {
  font-size: 0.8rem;
  margin-right: 5px;
  margin-bottom: -5px;
}

.partnership-logo {
  width: 60px; /* Adjust this value to control the size */
  height: auto;
  align-items: center;
  justify-content: center;
  padding-left: 143px;
  margin-bottom: -20px;
}

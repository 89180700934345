.inventory-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}

.all-sections {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 2rem;
}

.incubating,
.growing,
.fresh-inventory,
.dried-inventory {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 22%;
}

.dried-inventory {
  flex-direction: column;
}

.dehydrated-inventory,
.freeze-dried-inventory {
  flex: 1;
  padding-right: 1rem;
}

.dried-details {
  margin-top: -1rem;
  display: flex;
  justify-content: space-between;
}

.dried-contents {
  margin-left: -1rem;
}

h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

h5 {
  margin-top: -0.3rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: #444;
}

h6 {
  margin-top: 1rem;
  margin-bottom: 1.1rem;
}

p {
  font-size: 0.9rem;
  margin-left: 1rem;
  color: #555;
}

.tent-stats {
  font-size: 0.8rem;
  margin-left: 1rem;
  color: #777;
}

.transaction-sections {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  padding: 3rem;
  margin-left: 2rem;
}

.add-transactions,
.subtract-transactions {
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.transaction-sections h4 {
  margin-bottom: 1rem;
}

.transaction-sections ul {
  list-style-type: none;
  padding: 0;
}

.transaction-sections li {
  border-bottom: 1px solid #ddd;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.transaction-sections li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.transaction-table th {
  background-color: #f2f2f2;
  color: black;
}

.move-product-button {
  width: 400px; /* Change this to control the width of your buttons */
  margin-right: 10px; /* This adds some space between your buttons */
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.add-remove-product-inventory {
  width: 25%;
}

.product-added-success-modal {
  position: fixed; /* Fix position to the entire screen */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Black background with opacity */
  background-color: rgba(
    0,
    0,
    0,
    0.4
  ); /* Also adds black background with opacity */
}

.product-added-success-modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  text-align: center;
}

.product-added-success-modal-close-button {
  color: #aaa;
  float: right; /* Position it to the right */
  font-size: 28px;
  font-weight: bold;
}

.product-added-success-modal-close-button:hover,
.product-added-success-modal-close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.product-inventory {
  display: flex;
  flex-direction: column;
  align-items: center; /* Aligns items horizontally at the center */
  justify-content: center; /* Aligns items vertically at the center */
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 30%;
  margin-bottom: 2rem;
}

/* Add new rules for your table */
.product-inventory table {
  border-collapse: collapse;
  width: 100%; /* Adjust to your preference */
}

.product-inventory th,
.product-inventory td {
  text-align: center; /* Center the content horizontally */
  padding: 8px; /* Adjust the spacing inside the table cells */
  border: 1px solid #ddd; /* Border to separate the cells */
}

.product-inventory tr:nth-child(even) {
  background-color: #f2f2f2; /* Alternating row colors for readability */
}

.product-inventory th {
  background-color: #d80b0bcf; /* Or choose your preferred color */
  color: white;
}

/* Incubating, Growing, and Mushroom Inventory Tables */
.incubating table,
.growing table,
.fresh-inventory table,
.dried-inventory table {
  border-collapse: collapse;
  width: 100%; /* Adjust to your preference */
}

.incubating th,
.incubating td,
.growing th,
.growing td,
.fresh-inventory th,
.fresh-inventory td,
.dried-inventory th,
.dried-inventory td {
  text-align: center; /* Center the content horizontally */
  padding: 8px; /* Adjust the spacing inside the table cells */
  border: 1px solid #ddd; /* Border to separate the cells */
}

.incubating tr:nth-child(even),
.growing tr:nth-child(even),
.fresh-inventory tr:nth-child(even),
.dried-inventory tr:nth-child(even) {
  background-color: #f2f2f2; /* Alternating row colors for readability */
}

.incubating th,
.growing th,
.fresh-inventory th,
.dried-inventory th {
  background-color: #99bdff; /* Or choose your preferred color */
  color: rgb(0, 0, 0);
}

.transaction-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  margin-bottom: -35px;
}

.transaction-selection label {
  font-size: 1.2em;
}

.transaction-selection select {
  height: 40px; /* Adjust this value to your liking */
  width: 350px; /* Adjust this value to your liking */
  font-size: 1.1em; /* Adjust this value to your liking */
  margin-top: 15px;
}

.overall-gram-number {
  margin-top: -10px;
}

.growing-table-header {
  margin-bottom: 0;
  margin-top: 5px;
}

.date-selection {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.individual-date-selection {
  margin-left: 30px;
}

.analytics-table {
  width: 100%;
  border-collapse: collapse;
}

.analytics-table th,
.analytics-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.analytics-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.analytics-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #4caf50;
  color: white;
}

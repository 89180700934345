.discard-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 3%;
  z-index: 1000;
}

.discard-modal-content {
  position: relative;
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  max-height: 90vh;
  pointer-events: auto;
  transition: transform 0.1s linear;
  overflow-y: auto;
}

.discard-modal-close-btn {
  position: absolute;
  left: 92%;
  top: -3%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  color: black;
  cursor: pointer;
  font-size: 1.5em;
  text-align: center;
  width: 40px;
  height: 40px;
}

.discard-modal-close-btn:hover {
  color: white;
  background: black;
}

.header-2-smaller-font {
  font-size: 1.4rem;
}

.toggle-contamination-label {
  padding-right: 30px;
}

.discard-note-textarea {
  width: 100%;
  height: 60px;
}

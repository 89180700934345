body {
  font-family: Arial, sans-serif;
}

h1 {
  color: #4a5568;
}

h2 {
  color: #718096;
  font-style: italic;
}

.section-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 1rem;
  margin-bottom: 1rem;
}

.display-button {
  background-color: #3182ce;
  color: white;
  border: none;
  width: 205px;
  height: 45px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;
}

.display-button.active {
  background-color: #2c5282;
  border: 5px solid rgb(188, 41, 41);
}

.display-button:hover {
  background-color: #2c5282;
}

.sub-display-button {
  background-color: #9f7aea;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;
}

.sub-display-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.sub-display-button:hover {
  background-color: #805ad5;
}

.container {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
}

.culture-edit {
  margin-left: 2rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal button {
  margin: 10px;
  padding: 5px 10px;
  border-radius: 3px;
}

.modal-button-delete {
  margin: 10px;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: red;
}

.modal-button-delete:hover {
  background-color: #d81e1e;
}

.backup-dropdown-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.backup-button {
  background-color: #070707b8;
  color: white;
  border: none;
  width: 90px; /* adjust as needed */
  height: 25px; /* adjust as needed */
  padding: 2px 5px; /* adjust as needed */
  font-size: 0.5rem; /* adjust as needed */
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease;
}

.backup-button:hover {
  background-color: #8c8c8f;
}
